import { take, put, call } from "redux-saga/effects";

import { IAction } from "../../schema";
import {
  IGetProfileArgs,
  userConst,
  getProfileAction,
  IGetProfile,
  IVerifyProfileArgs,
  IVerifyProfile,
  verifyProfileAction,
  IRegisterProfileArgs,
  registerProfileAction,
  IRegisterProfile,
  IInvalidateTokenArgs,
  invalidateTokenAction,
  IInvalidateToken,
  IResendVerificationEmailArgs,
  resendVerificationEmailAction,
  IResendVerificationEmail,
  IAddEmailArgs,
  addEmailAction,
  IAddEmail,
  IReauthArgs,
  reauthAccountAction,
  IUpdateProfileArgs,
  updateProfileAction,
  IUpdateProfile,
  ITrackSignupArgs,
  trackSignupAction,
  registerRequestAction,
  IRegisterRequestArgs,
  IRegisterRequest,
  ILoginWithMagicLinkRequestArgs,
  loginWithMagicLinkRequestAction,
  ICheckMagicLinkAuthStatusRequestArgs,
  checkMagicLinkAuthStatusRequestAction,
  ICheckMagicLinkAuthStatusRequest,
  IResendMagicLinkRequestArgs,
  resendMagicLinkRequestAction,
  ILoginWithMagicLink,
} from "../actions/user";
import Http from "../../services/http";
import { RegisterHttp } from "../../services/http";
import config from "../../config";

// Workers
function* handleGetProfile(args: IGetProfileArgs) {
  try {
    yield put(getProfileAction.loading());

    const resp: IGetProfile = yield new Http({
      endpoint: config.idp_endpoint,
    }).get("/v1/auth/sso/userinfo", {
      client_id: config.idp_apikey,
      access_token: args.token,
    });

    if (resp.code && resp.message) {
      yield put(checkMagicLinkAuthStatusRequestAction.error(resp.message));
    } else {
      yield put(getProfileAction.success(resp));
    }
  } catch (e) {
    // @ts-ignore
    yield put(getProfileAction.error(e.message));
  }
}

function* handleVerifyProfile(args: IVerifyProfileArgs) {
  try {
    yield put(verifyProfileAction.loading());

    const resp: IVerifyProfile = yield new Http({
      endpoint: config.idp_endpoint,
    }).put(
      "/identity/v2/auth/email",
      { apiKey: config.idp_apikey },
      JSON.stringify({ otp: args.otp, email: args.email })
    );

    if (resp.ErrorCode) {
      throw new Error(resp.Message);
    }

    yield put(verifyProfileAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(verifyProfileAction.error(e.message));
  }
}

function* handleRegisterProfile(args: IRegisterProfileArgs) {
  try {
    yield put(registerProfileAction.loading());

    const resp: IRegisterProfile = yield RegisterHttp.post(
      "/identity/v2/auth/register",
      { apiKey: config.idp_apikey },
      JSON.stringify({
        email: args.email,
        password: args.password,
        firstName: args.firstName,
        company: args.company,
        customFields: args.customFields,
      })
    );

    if (resp.ErrorCode) {
      throw resp;
    }

    yield put(registerProfileAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(registerProfileAction.error2(e.Message, e.ErrorCode));
  }
}

function* invalidateTokenHandler(args: IInvalidateTokenArgs) {
  try {
    yield put(invalidateTokenAction.loading());

    const resp: IInvalidateToken = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get("/account/logout");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(invalidateTokenAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(invalidateTokenAction.error(e.message));
  }
}

function* handleResendVerificationEmail(args: IResendVerificationEmailArgs) {
  try {
    yield put(resendVerificationEmailAction.loading());

    const resp: IResendVerificationEmail = yield new Http({
      endpoint: config.idp_endpoint,
    }).put(
      "/identity/v2/auth/register",
      { apiKey: config.idp_apikey },
      JSON.stringify(args)
    );

    if (resp.ErrorCode) {
      throw new Error(resp.Message);
    }

    yield put(resendVerificationEmailAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(resendVerificationEmailAction.error(e.message));
  }
}

function* handleAddEmail(args: IAddEmailArgs) {
  try {
    yield put(addEmailAction.loading());

    const resp: IAddEmail = yield new Http({
      endpoint: config.idp_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).post(
      "/identity/v2/auth/email",
      { apiKey: config.idp_apikey },
      JSON.stringify(args)
    );

    if (resp.ErrorCode) {
      if (resp.ErrorCode === 936) {
        throw new Error("Email address is already registered!");
      } else {
        throw new Error(resp.Message);
      }
    }

    yield put(addEmailAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(addEmailAction.error(e.message));
  }
}

function* handleReauthAccount(args: IReauthArgs) {
  try {
    yield put(reauthAccountAction.loading());

    const resp: IAddEmail = yield new Http({
      endpoint: config.idp_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).put(
      "/identity/v2/auth/account/reauth/password",
      { apiKey: config.idp_apikey },
      JSON.stringify(args)
    );

    if (resp.ErrorCode) {
      throw new Error(resp.Message);
    }

    yield put(reauthAccountAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(reauthAccountAction.error(e.message));
  }
}

function* handleUpdateProfile(args: IUpdateProfileArgs) {
  try {
    yield put(updateProfileAction.loading());

    const resp: IUpdateProfile = yield new Http({
      endpoint: config.idp_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).put(
      "/identity/v2/auth/account",
      { apiKey: config.idp_apikey },
      JSON.stringify(args.Payload, (k, v) => v ?? undefined)
    );

    if (resp.ErrorCode) {
      throw new Error(resp.Message);
    }

    yield put(updateProfileAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(updateProfileAction.error(e.message));
  }
}

function* handleTrackSignup(args: ITrackSignupArgs) {
  try {
    yield put(trackSignupAction.loading());

    const resp: IUpdateProfile = yield new Http({
      endpoint: config.api_endpoint,
    }).post("/leads/signup/track", {}, JSON.stringify(args));

    if (resp.ErrorCode) {
      throw new Error(resp.Message);
    }

    yield put(updateProfileAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(updateProfileAction.error(e.message));
  }
}

function* handleSignupRequestLead(args: IRegisterRequestArgs) {
  try {
    yield put(registerRequestAction.loading());

    const resp: IRegisterRequest = yield new Http({
      endpoint: config.api_endpoint,
    }).post("/leads/signup/request", {}, JSON.stringify(args));

    if (resp.ErrorCode) {
      throw new Error(resp.Message);
    }

    yield put(registerRequestAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(registerRequestAction.error(e.message));
  }
}

function* handleLoginWithMagicLinkRequest(
  args: ILoginWithMagicLinkRequestArgs
) {
  try {
    yield put(loginWithMagicLinkRequestAction.loading());

    const resp: ILoginWithMagicLink = yield new Http({
      endpoint: config.idp_endpoint,
    }).post(
      "/v1/auth/passwordless/magic-link/send",
      { client_id: config.idp_apikey },
      JSON.stringify({ email: args.email, redirect_url: args.redirectUrl })
    );
    if (resp.code && resp.message) {
      yield put(loginWithMagicLinkRequestAction.error(resp.message));
    } else {
      yield put(
        loginWithMagicLinkRequestAction.success({ state_id: resp.state })
      );
    }
  } catch (e) {
    // @ts-ignore
    yield put(loginWithMagicLinkRequestAction.error(e.message));
  }
}

function* handleCheckMagicLinkAuthStatusRequest(
  args: ICheckMagicLinkAuthStatusRequestArgs
) {
  try {
    yield put(checkMagicLinkAuthStatusRequestAction.loading());

    const resp: ICheckMagicLinkAuthStatusRequest = yield new Http({
      endpoint: config.idp_endpoint,
    }).get("/v1/auth/passwordless/magic-link/status", {
      client_id: config.idp_apikey,
      state: args.state_id,
    });

    if (resp.code && resp.message) {
      yield put(checkMagicLinkAuthStatusRequestAction.error(resp.message));
    } else {
      yield put(checkMagicLinkAuthStatusRequestAction.success(resp));
    }
  } catch (e) {
    // @ts-ignore
    yield put(checkMagicLinkAuthStatusRequestAction.error(e.message));
  }
}

function* handleResendMagicLinkRequest(args: IResendMagicLinkRequestArgs) {
  try {
    yield put(resendMagicLinkRequestAction.loading());

    const resp: ILoginWithMagicLink = yield new Http({
      endpoint: config.idp_endpoint,
    }).post(
      "/v1/auth/passwordless/magic-link/resend",
      { client_id: config.idp_apikey },
      JSON.stringify({ state: args.state_id, redirect_url: args.redirectUrl })
    );
    if (resp.code && resp.message) {
      yield put(resendMagicLinkRequestAction.error(resp.message));
    } else {
      yield put(resendMagicLinkRequestAction.success({ state_id: resp.state }));
    }
  } catch (e) {
    // @ts-ignore
    yield put(resendMagicLinkRequestAction.error(e.message));
  }
}

// Watchers
export function* watchGetProfile() {
  while (true) {
    const { payload }: IAction<IGetProfileArgs> = yield take(
      userConst.FETCH_PROFILE
    );
    if (!(payload as any).state) {
      yield call(handleGetProfile, payload);
    }
  }
}

export function* watchVerifyProfile() {
  while (true) {
    const { payload }: IAction<IVerifyProfileArgs> = yield take(
      userConst.VERIFY_PROFILE
    );
    if (!(payload as any).state) {
      yield call(handleVerifyProfile, payload);
    }
  }
}

export function* watchRegisterProfile() {
  while (true) {
    const { payload }: IAction<IRegisterProfileArgs> = yield take(
      userConst.REGISTER_PROFILE
    );
    if (!(payload as any).state) {
      yield call(handleRegisterProfile, payload);
    }
  }
}

export function* watchInvalidateToken() {
  while (true) {
    const { payload }: IAction<IInvalidateTokenArgs> = yield take(
      userConst.INVALIDATE_TOKEN
    );
    if (!(payload as any).state) {
      yield call(invalidateTokenHandler, payload);
    }
  }
}

export function* watchResendVerificationEmail() {
  while (true) {
    const { payload }: IAction<IResendVerificationEmailArgs> = yield take(
      userConst.RESEND_VERIFICATION_EMAIL
    );
    if (!(payload as any).state) {
      yield call(handleResendVerificationEmail, payload);
    }
  }
}

export function* watchAddEmail() {
  while (true) {
    const { payload }: IAction<IAddEmailArgs> = yield take(userConst.ADD_EMAIL);
    if (!(payload as any).state) {
      yield call(handleAddEmail, payload);
    }
  }
}

export function* watchReauthAccount() {
  while (true) {
    const { payload }: IAction<IReauthArgs> = yield take(
      userConst.REAUTH_ACCOUNT
    );
    if (!(payload as any).state) {
      yield call(handleReauthAccount, payload);
    }
  }
}

export function* watchUpdateProfile() {
  while (true) {
    const { payload }: IAction<IUpdateProfileArgs> = yield take(
      userConst.UPDATE_PROFILE
    );
    if (!(payload as any).state) {
      yield call(handleUpdateProfile, payload);
    }
  }
}

export function* watchTrackSignup() {
  while (true) {
    const { payload }: IAction<ITrackSignupArgs> = yield take(
      userConst.TRACK_SIGNUP
    );
    if (!(payload as any).state) {
      yield call(handleTrackSignup, payload);
    }
  }
}

export function* watchSignupRequestLead() {
  while (true) {
    const { payload }: IAction<IRegisterRequestArgs> = yield take(
      userConst.REGISTER_REQUEST_LEAD
    );
    if (!(payload as any).state) {
      yield call(handleSignupRequestLead, payload);
    }
  }
}

export function* watchLoginWithMagicLink() {
  while (true) {
    const { payload }: IAction<ILoginWithMagicLinkRequestArgs> = yield take(
      userConst.LOGIN_MAGIC_LINK
    );
    if (!(payload as any).state) {
      yield call(handleLoginWithMagicLinkRequest, payload);
    }
  }
}

export function* watchCheckMagicLinkAuthStatus() {
  while (true) {
    const {
      payload,
    }: IAction<ICheckMagicLinkAuthStatusRequestArgs> = yield take(
      userConst.CHECK_MAGIC_LINK_AUTH_STATUS
    );
    if (!(payload as any).state) {
      yield call(handleCheckMagicLinkAuthStatusRequest, payload);
    }
  }
}

export function* watchResendMagicLink() {
  while (true) {
    const { payload }: IAction<IResendMagicLinkRequestArgs> = yield take(
      userConst.RESEND_MAGIC_LINK
    );
    if (!(payload as any).state) {
      yield call(handleResendMagicLinkRequest, payload);
    }
  }
}
